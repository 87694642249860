<template>
  <q-card class="my-card" flat v-if="onCartDrawer">
    <q-item
      :class="[
        'item-start',
        shoppingCartItem.linkedProducts?.length > 0 ? 'link-products' : '',
      ]"
    >
      <q-item-section avatar>
        <box-image
          :link="{
            name: 'Detail',
            params: { seoName: shoppingCartItem.seoName },
          }"
          :imageSrc="shoppingCartItem.image"
          width="40px"
        />
      </q-item-section>
      <q-item-section>
        <q-item-label class="product-title ellipsis d">
          <router-link
            :to="{
              name: 'Detail',
              params: { seoName: shoppingCartItem.seoName },
            }"
            v-html="shoppingCartItem.productName"
          />
          <q-tooltip max-width="180px" content-class="bg-dark">
            {{ shoppingCartItem.productName }}
          </q-tooltip>
        </q-item-label>
        <q-item-label class="row no-wrap items-center" caption>
          <AddToCartBtn
            :showMaxErrorOnTop="false"
            :showFullFillment="false"
            :productId="shoppingCartItem.productId"
            :productFulFillments="shoppingCartItem.productFulFillments"
            :cartLineItem="shoppingCartItem"
            :fulFillmentCodeName="fulFillmentCodeName"
            :isinstock="shoppingCartItem.isinstock"
          />
          <q-item-label
            class="q-ml-sm product-qty"
            v-html="`X ${$options.filters.currency(shoppingCartItem.price)}`"
          />
        </q-item-label>
        <!-- <q-item-label class="items-right" caption>
          <span
            class="discount f11 fwnormal bg q-mb-none"
            v-if="shoppingCartItem.discountAmount"
            v-html="
              `You Saved: ${$options.filters.currency(
                Math.abs(shoppingCartItem.discountAmount),
                true
              )}`
            "
          />
        </q-item-label> -->
      </q-item-section>
      <q-item-section class="cartProductSide" side>
        <Trash :orderItemId="shoppingCartItem.orderLineItemID" />
        <!-- <q-item-label
          class="text-strike strike-price q-pt-xs"
          v-html="$options.filters.currency(shoppingCartItem.totalPrice)"
          v-if="shoppingCartItem.finalPrice != shoppingCartItem.totalPrice"
        /> -->
        <q-item-label
          class="product-price"
          v-html="$options.filters.currency(shoppingCartItem.totalPrice)"
        />
      </q-item-section>
      <q-item-section
        class="linked-product bg-primary"
        v-if="shoppingCartItem.linkedProducts?.length > 0"
      >
        <q-item-label>
          <!-- <q-badge color="white" text-color="primary" class="inline-block">
            Linked Product
          </q-badge> -->
          <q-item
            class="item-start"
            v-for="(linkedProduct, index) in shoppingCartItem.linkedProducts"
            :key="`${linkedProduct.orderLineItemID}${index}`"
          >
            <q-item-section avatar>
              <box-image
                :link="
                  linkedProduct.isSellable
                    ? {
                        name: 'Detail',
                        params: { seoName: linkedProduct.seoName },
                      }
                    : ''
                "
                :imageSrc="linkedProduct.image"
                width="40px"
              />
            </q-item-section>
            <q-item-section>
              <q-item-label class="product-title ellipsis d">
                <router-link
                  v-if="linkedProduct.isSellable"
                  :to="{
                    name: 'Detail',
                    params: { seoName: linkedProduct.seoName },
                  }"
                  v-html="linkedProduct.productName"
                />
                <template v-else>
                  {{ linkedProduct.productName }}
                </template>
                <q-tooltip max-width="180px" content-class="bg-dark">
                  {{ linkedProduct.productName }}
                </q-tooltip>
              </q-item-label>
              <q-item-label class="row no-wrap items-center" caption>
                <q-item-label
                  class="product-qty"
                  v-html="
                    `${linkedProduct.qty} X ${$options.filters.currency(
                      linkedProduct.price
                    )}`
                  "
                />
              </q-item-label>
              <!-- <q-item-label class="items-right" caption>
                <span
                  class="discount f11 fwnormal bg q-mb-none"
                  v-if="linkedProduct.discountAmount"
                  v-html="
                    `You Saved: ${$options.filters.currency(
                      linkedProduct.discountAmount,
                      true
                    )}`
                  "
                />
              </q-item-label> -->
            </q-item-section>
            <q-item-section class="cartProductSide" side>
              <!-- <q-item-label
                class="text-strike strike-price text-grey text-weight-regular"
                v-html="$options.filters.currency(linkedProduct.totalPrice)"
                v-if="linkedProduct.totalPrice != linkedProduct.finalPrice"
              /> -->
              <q-item-label
                class="product-price"
                v-html="$options.filters.currency(linkedProduct.totalPrice)"
              />
            </q-item-section>
          </q-item>
        </q-item-label>
      </q-item-section>
    </q-item>
  </q-card>
  <div v-else class="cartItemLi">
    <div
      :class="[
        'cartItem',
        shoppingCartItem.linkedProducts &&
        shoppingCartItem.linkedProducts.length > 0
          ? 'link-products'
          : '',
      ]"
    >
      <div
        v-if="
          businessConfigurations.enableSubstitution &&
          fulFillmentCodeName !== 'sd'
        "
        class="cartItemCheck"
      >
        <q-checkbox
          color="secondaryOnBody"
          :value="
            substitute(
              shoppingCartItem.orderLineItemID,
              shoppingCartItem.allowSubstitute
            )
          "
          @input="
            (val) => {
              OnSubstituteChange(val, shoppingCartItem.allowSubstitute, {
                productID: shoppingCartItem.productId,
                fulfillmentMethodID: fulFillmentId,
                orderLineItemID: shoppingCartItem.orderLineItemID,
              })
            }
          "
        />
      </div>
      <div class="cartItemDet">
        <div class="cartItemDesc">
          <div class="cartItemThumb">
            <box-image
              :link="{
                name: 'Detail',
                params: { seoName: shoppingCartItem.seoName },
              }"
              :imageSrc="shoppingCartItem.image"
            />
          </div>
          <div class="cartItemTitle">
            <div class="ellipsis-2-lines">
              <router-link
                :to="{
                  name: 'Detail',
                  params: { seoName: shoppingCartItem.seoName },
                }"
                v-html="shoppingCartItem.productName"
              />
            </div>
            <OrderLevelinstruction
              :productId="shoppingCartItem.productId"
              :productFulFillments="shoppingCartItem.productFulFillments"
              :cartLineItem="shoppingCartItem"
              :isfromEditOrderLevel="false"
            />
          </div>
        </div>
        <div class="cartItemSelect">
          <AddToCartBtn
            :showMaxErrorOnTop="false"
            :productId="shoppingCartItem.productId"
            :productFulFillments="shoppingCartItem.productFulFillments"
            :cartLineItem="shoppingCartItem"
            :fulFillmentCodeName="fulFillmentCodeName"
            :isinstock="shoppingCartItem.isinstock"
            ref="lineItemBtn"
          />
        </div>
        <div class="cartItemQty">
          <div class="price">
            <!-- <span
              class="discount q-mb-xs"
              v-if="shoppingCartItem.discountAmount"
            >
              You Saved:
              {{ Math.abs(shoppingCartItem.discountAmount) | currency(false) }}
            </span> -->
            x {{ shoppingCartItem.price | currency }}
            <span
              v-if="false"
              class="text-strike"
              v-html="
                $options.filters.currency(shoppingCartItem.compareAtPrice)
              "
            />
          </div>
        </div>
      </div>
      <div class="cartItemPrice">
        <span
          class="text-strike strike-price text-grey text-weight-regular"
          v-html="$options.filters.currency(shoppingCartItem.totalPrice)"
          v-if="shoppingCartItem.totalPrice != shoppingCartItem.finalPrice"
        />
        <span
          class="price"
          v-html="$options.filters.currency(shoppingCartItem.finalPrice)"
        />
      </div>
      <Trash :orderItemId="shoppingCartItem.orderLineItemID" />

      <div
        class="linked-product bg-primary"
        v-if="
          shoppingCartItem.linkedProducts &&
          shoppingCartItem.linkedProducts.length > 0
        "
      >
        <q-item
          class="item-start"
          v-for="(linkedProduct, index) in shoppingCartItem.linkedProducts"
          :key="`${linkedProduct.orderLineItemID}${index}`"
        >
          <q-item-section avatar>
            <box-image
              :link="
                linkedProduct.isSellable
                  ? {
                      name: 'Detail',
                      params: { seoName: linkedProduct.seoName },
                    }
                  : ''
              "
              :imageSrc="linkedProduct.image"
              width="40px"
            />
          </q-item-section>
          <q-item-section>
            <q-item-label class="product-title ellipsis d">
              <router-link
                v-if="linkedProduct.isSellable"
                :to="{
                  name: 'Detail',
                  params: { seoName: linkedProduct.seoName },
                }"
                v-html="linkedProduct.productName"
              />
              <template v-else>
                {{ linkedProduct.productName }}
              </template>
              <q-tooltip max-width="180px" content-class="bg-dark">
                {{ linkedProduct.productName }}
              </q-tooltip>
            </q-item-label>
            <q-item-label class="row no-wrap items-center" caption>
              <q-item-label class="product-qty" />
            </q-item-label>
            <q-item-label class="items-right" caption> </q-item-label>
          </q-item-section>
          <q-item-section> </q-item-section>
          <q-item-section class="linked-product-qty">
            <div class="row q-col-gutter-sm items-center">
              <div
                class="col-12 caption q-pl-none"
                v-html="
                  `${linkedProduct.qty} X ${$options.filters.currency(
                    linkedProduct.price
                  )}`
                "
              />
            </div>
          </q-item-section>
          <q-item-section class="cartProductSide justify-end" side>
            <div
              class="product-price cartItemPrice flex full-width items-center"
            >
              <q-item-label
                class="text-strike strike-price text-grey text-weight-regular"
                v-html="$options.filters.currency(linkedProduct.totalPrice)"
                v-if="linkedProduct.totalPrice != linkedProduct.finalPrice"
              />
              <q-item-label
                class=" "
                v-html="$options.filters.currency(linkedProduct.finalPrice)"
              />
            </div>
          </q-item-section>
        </q-item>
      </div>
    </div>
    <div
      class="cartsubstitute"
      v-if="
        (fulFillmentCodeName !== 'sd' &&
          substitute(
            shoppingCartItem.orderLineItemID,
            shoppingCartItem.allowSubstitute
          ) &&
          shoppingCartItem.substituteProductDetails &&
          shoppingCartItem.substituteProductDetails.length) ||
        substituteChildProductDetails(
          shoppingCartItem.orderLineItemID,
          shoppingCartItem.allowSubstitute
        ).length
      "
    >
      <span class="cartsubstitute-title"
        >Choose Your Preferred Substitution</span
      >
      <!-- <div
        class="cartItem"
        v-for="(
          substituteProduct, index
        ) in shoppingCartItem.substituteProductDetails"
        :key="`${substituteProduct.orderLineItemID}${index}`"
      > -->
      <div
        class="cartItem"
        v-for="(substituteProduct, index) in substituteProductList"
        :key="`${substituteProduct.productID}${index}`"
      >
        <div class="cartItemCheck">
          <!--<q-checkbox
            color="secondaryOnBody"
            v-if="fulFillmentCodeName !== 'sd'"
            :value="
              substitute(
                shoppingCartItem.orderLineItemID,
                shoppingCartItem.allowSubstitute
              )
            "
            @input="
              () => {
                this.toggleSubstitute({
                  orderLineItemId: shoppingCartItem.orderLineItemID,
                  allowSubstitute: shoppingCartItem.allowSubstitute,
                })
              }
            "
          />-->
          <q-radio
            color="secondaryOnBody"
            v-if="fulFillmentCodeName !== 'sd'"
            :val="
              selectedChildSubstituteProduct(
                shoppingCartItem.orderLineItemID
              ) ||
              shoppingCartItem.selectedSubstituteProductId ||
              (substituteProduct.substituteProductID ==
              '00000000-0000-0000-0000-000000000000'
                ? substituteProduct.substituteProductID
                : '')
            "
            :value="substituteProduct.substituteProductID"
            @input="
              () => {
                toggleSubstituteChildProduct({
                  orderLineItemID: shoppingCartItem.orderLineItemID,
                  allowSubstitute: shoppingCartItem.allowSubstitute,
                  substituteProductID: substituteProduct.substituteProductID,
                })
              }
            "
          />
        </div>
        <div class="cartItemDet">
          <div class="cartItemDesc">
            <div
              class="cartItemThumb"
              v-if="substituteProduct.productName != 'No Preference'"
            >
              <box-image
                :link="{
                  name: 'Detail',
                  params: { seoName: substituteProduct.seoName },
                }"
                :imageSrc="substituteProduct.image | defaultProductImage"
              />
            </div>
            <div v-if="substituteProduct.productName != 'No Preference'">
              <div class="cartItemTitle ellipsis-2-lines">
                <router-link
                  :to="{
                    name: 'Detail',
                    params: { seoName: substituteProduct.seoName },
                  }"
                  v-html="substituteProduct.productName"
                />
              </div>
              <div class="cartItemPrice text-left">
                <span
                  class="price"
                  v-html="$options.filters.currency(substituteProduct.price)"
                />
              </div>
            </div>
            <div v-else> {{ substituteProduct.productName }} </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import AddToCartBtn from '../AddToCartBtn'
import Trash from './Trash'
import { hideShowFullSpinner } from 'src/utils'
import OrderLevelinstruction from '../OrderLevelinstruction'

export default {
  props: {
    shoppingCartItem: {
      type: Object,
      default: null,
    },
    fulFillmentId: {
      type: String,
      default: '',
    },
    onCartDrawer: {
      type: Boolean,
      default: false,
    },
    fulFillmentCodeName: {
      type: String,
      default: '',
    },
    toggleSubstitute: {
      type: Function,
      default: () => {},
    },
    substitute: {
      type: Function,
      default: () => {},
    },
  },
  components: {
    AddToCartBtn,
    Trash,
    OrderLevelinstruction,
  },
  computed: {
    ...mapGetters('cart', [
      'substituteChildProductDetails',
      'selectedChildSubstituteProduct',
    ]),
    substituteProductList() {
      let data = []
      if (this.shoppingCartItem) {
        let data = this.substituteChildProductDetails(
          this.shoppingCartItem.orderLineItemID,
          this.shoppingCartItem.allowSubstitute
        )
        return data.length
          ? data
          : this.shoppingCartItem.substituteProductDetails
      }
      return data
    },
  },
  methods: {
    ...mapActions('cart', ['toggleSubstituteChildProduct']),
    OnSubstituteChange: async function (isChecked, allowSubstitute, params) {
      if (isChecked) {
        hideShowFullSpinner(true)
        await this.$store
          .dispatch('cart/getAllSubstituteItemsForProduct', [params])
          .then((response) => {
            if (response.success) {
              let noPreferenceOption = {
                image: '',
                price: 0,
                productID: '00000000-0000-0000-0000-000000000000',
                productName: 'No Preference',
                qtyOnHand: 0,
                seoName: '',
                substituteProductID: '00000000-0000-0000-0000-000000000000',
              }
              let substituteProducts =
                Array.isArray(response.data) && response.data.length
                  ? [
                      ...response.data[0].substituteProductDetails,
                      noPreferenceOption,
                    ]
                  : []
              this.toggleSubstitute({
                orderLineItemId: params.orderLineItemID,
                allowSubstitute: allowSubstitute,
                substituteProducts: substituteProducts,
              })
            } else {
              this.showError(response.message)
            }
          })
          .finally(() => {
            hideShowFullSpinner(false)
          })
      } else {
        this.toggleSubstitute({
          orderLineItemId: params.orderLineItemID,
          allowSubstitute: allowSubstitute,
          substituteProducts: [],
        })
      }
    },
  },
}
</script>
<style lang="scss">
.cartItemList {
  .cartsubstitute {
    position: relative;
    margin-left: 32px;
    padding-top: 15px;
    border: 1px dashed $primary;
    border-radius: 7px;
    margin-top: 15px;
    .cartsubstitute-title {
      position: absolute;
      top: -10px;
      left: 5px;
      padding: 4px 15px;
      background: #ebebeb;
      border-radius: 7px;
      line-height: 1;
      font-size: 14px;
    }
    .cartItem {
      padding: 10px 0;
      @media (max-width: 1024px) {
        border-top: none;
        .cartItemDet {
          padding-right: 15px;
          padding-bottom: 0px;
          min-height: auto;
          align-self: center;
        }
        .cartItemDesc {
          padding-bottom: 0;
        }
      }
      .cartItemPrice {
        text-align: left;
        margin-top: 5px;
        font-size: 16px;
        position: relative;
        left: 0;
        top: 0;
      }
    }
  }
}
</style>
